/* * {
  box-sizing: border-box;
} */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  max-height: 100vh;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
}

.App * {
  box-sizing: border-box;
}

.App .container {
  width: 1200px;
  display: flex;
  max-height: 100vh;
}

.App .container .form-container {
  margin-right: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
  overflow: scroll;
}

.App .container .pdf-container {
  margin-left: 20px;
}

.App .container .col {
  flex: 1 1;
}

.App .footer {
  width: 1200px;
  border: 1px solid #ccc;
  background: #fff;
  padding: 10px 20px;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
}

